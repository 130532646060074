* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
body {
	background-color: #000000;
	/* background-color: #333333; */
}

#model-load-svg {
	margin: 0 auto;
	width: 50%;
	position: relative;
	top: -30px;
}

.image-bg {
	background-color: #000000;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.modal {
	position: absolute;
	bottom: 0;
	margin: 15px 0;
	padding: 22px 0;
	min-width: 330px;
	z-index: 10000000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #00000090;
	color: #ffffff;
	font-family: Cinzel;
	transition: opacity 0.5s ease;
	text-align: center;
	transform: translateX(-50%);
	left: 50%;
}
@media screen and (min-width: 500px) {
	.modal {
		bottom: 30px;
		max-width: 400px;
	}
}
.modal h1 {
	padding: 0;
	letter-spacing: 5px;
	text-transform: uppercase;
}
.modal p {
	padding: 10px 22px;
	margin-bottom: 10px;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 1px;
	font-family: Helvetica, sans-serif;
}
.button {
	cursor: pointer;
	background-color: #07ff00;
	font-family: Helvetica, sans-serif;
	text-transform: uppercase;
	min-height: 50px;
	display: flex;
	align-items: center;
}
.button p {
	padding: 5px 30px;
	margin-bottom: 0;
	color: #000000;
	font-weight: 700;
}
.end-screen {
	background-color: #000000;
	width: 100vw;
	height: 100vh;
	color: #ffffff;
	text-align: center;
	padding: 30px 0 0 0;
	font-family: Cinzel;
	display: flex;
	flex-direction: column;
	gap: 70px;
	align-items: center;
	transition: opacity 0.5s ease;
}
.end-screen h1 {
	text-transform: uppercase;
	letter-spacing: 5px;
	line-height: 32px;
}
.end-screen p {
	font-weight: 700;
	margin-top: 5px;
	font-size: 14px;
	padding: 0 50px;
	font-family: Helvetica, sans-serif;
}

.end-screen .button-text {
	color: #07ff00;
	text-align: center;
	margin: 0 auto;
	margin-top: 20px;
	font-weight: 700;
	line-height: 30px;
	min-height: 50px;
	font-family: Helvetica, sans-serif;
	text-transform: uppercase;
	border: 2px solid #07ff00;
	display: flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	padding: 0 22px;
}
.end-screen a {
	text-decoration: none;
}
.logo-and-link {
	padding: 0 91px;
}
.logo-and-link img {
	width: 100%;
}

.touch-indicator {
	animation: floating 3s ease-in-out infinite;
	position: absolute;
	right: 0;
	margin: 28px;
	z-index: 10000000;
	transition: opacity 0.5s ease;
}
@media screen and (min-width: 500px) {
	.touch-indicator {
		right: 30%;
	}
}
.touch-indicator.hidden {
	display: none;
}

@keyframes floating {
	0% {
		transform: translate(0, 0px);
	}
	50% {
		transform: translate(0, 15px);
	}
	100% {
		transform: translate(0, -0px);
	}
}
